import * as React from 'react'
import HomeMenu from './menu'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { withIdentity, IdentityProps } from "src/core/services/authentication"
import { withTranslation, WithTranslation } from 'react-i18next'
import { isNullOrWhitespace } from 'src/core/utils/object'
import "./home.scss"
import { LoadingOutlined } from '@ant-design/icons'
import logo from '../../assets/homeLogo.png'
import { Spin, Card, Image, Row, Col, Divider } from 'antd'

const Home: React.FC<RouteComponentProps & IdentityProps & WithTranslation> = (props) => {
  const { t } = props
  const getDisplayName = () => {
    var result = `${props.identity.firstName || ''} ${props.identity.lastName || ''}`
    if (isNullOrWhitespace(result)) {
      result = props.identity.profile.name
    }
    return result
  }
  const [displayName] = React.useState(getDisplayName())


  React.useEffect(() => {
    document.title = "FI Group - FI Connect"
  }, [])

  return <>
    <div className={"home-page "}>

      <div className={"ibox"} style={{ height: 60, background: "white", marginBottom: 10 }}>
        <Row >
          <Col span={24} style={{ display: "flex", textAlign: "center" }}>

            <div style={{ margin: "auto", display: 'flex' }}>
              <img style={{ float: "right" }} height={60} src={logo}></img>

              <Divider style={{
                height: '40px',
                color: 'rgb(0, 0, 164)',
                borderLeft: '2px solid rgb(0, 0, 164)',
                margin: 10
              }} type="vertical"></Divider>

              <h1 className="welcome-message" style={{ color: "rgb(0,0,164)", fontWeight: "bold" }}>{t("Welcome")} </h1>
              <h1 className="welcome-message" > {displayName} </h1>
            </div>
          </Col>

        </Row>
      </div>

      <Route exact path={`${props.match.path}`} component={HomeMenu} />
    </div>
  </>
}

export default withTranslation()(withRouter(withIdentity(Home) as any) as any)
