import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, Alert, Spin, Row, Col, Input, Card } from 'antd'
import { withIdentity, IdentityProps, isInRole } from 'src/core/services/authentication'
import 'src/core/utils/linq'
import HttpService, { formatMessage } from '../../core/services/http.service'
import { FC, Fragment, useState } from 'react'

import { container } from '../../inversify.config'
import { QueryResult } from '../../core/stores/data-store'
import { CloseOutlined, ForwardFilled, HeartFilled, HeartOutlined, LinkOutlined, LoadingOutlined, MenuOutlined, OrderedListOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'
import logo from 'src/core/assets/logo.png'
import app from '../../app'
import Tooltip from 'antd/es/tooltip'
import { CacheProps, withCache, cacheService, TimeType } from '../../core/services/cache.service'
const { Search } = Input
import { cache } from '@emotion/css'
import SortAppList from './sortAppList'
interface AdminMenuProps extends RouteComponentProps, WithTranslation, IdentityProps, CacheProps {

}

export interface AppInfo {
  id: string,
  title: string,
  description: string,
  icon: string,
  privateUrl: string,
  publicUrl: string,
  prerelease: boolean,
  hasAccess: boolean,
  name: string
  isUserFavorite: boolean
}

const AppIcon: FC<{ icon?: string, id?: string, cache: CacheProps }> = (props) => {

  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [iconSource64, setIconSource64] = useState(props.icon)
  const inputRef = React.useRef()
  const cacheImageKey = "IMAGESOURCE64"

  React.useEffect(
    () => {
      var cachedImage = props.cache.cache.getGlobal(cacheImageKey + props.id)
      if (cachedImage) {
        setIconSource64(cachedImage as any)
        return
      }
      if (props.id) {
        setBusy(true)
        httpService.get<QueryResult<AppInfo>>("/api/v1/admin/applications/icon/" + props.id).then(result => {
          setIconSource64(result.data as any)
          props.cache.cache.saveGlobal(cacheImageKey + props.id, result.data, { value: 1, type: TimeType.Hour })
          setBusy(false)

        }).catch(error => {
          setBusy(false)
        })
      }
    }, [inputRef]
  )

  return !busy ?
    <img width={48} height={48} style={{
      height: "48px",
      width: "48px",
      backgroundColor: "rgb(0, 0, 164)",
      borderRadius: "25px",
      marginRight: 20

    }} src={iconSource64} alt="icon" /> : <LoadingOutlined style={{ fontSize: "48px", color: "white" }} />

}

function getParameterByName(url: string, name: string) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const AdminMenu: FC<AdminMenuProps> = (props) => {

  const [whileRedirect, setwhileRedirect] = React.useState<boolean>(true)
  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [onlyFavorites, setOnlyFavorites] = React.useState(props.cache.getGlobal("IdentityOnlyFavorites") == "1")
  const [additionalApps, setAdditionalApps] = useState<AppInfo[]>([])
  const [error, setError] = useState<string>(undefined)
  const inputRef = React.useRef()
  const [apps, setApps] = useState<AppInfo[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [searchPattern, setSearchPatter] = useState("")
  const [orderActive, setOrderActive] = useState(false)

  const hasAccessWrapper = (app: AppInfo): boolean => {
    return app.hasAccess && (app.id != "1" || isInRole(props.identity, ["Administrator"]))
  }

  React.useEffect(
    () => {
      setBusy(true)
      httpService.get<{ applications: QueryResult<AppInfo>, clientPortalAppId: string }>("/api/v1/admin/applications/homeInfo").then(result => {
        let redirectToClientPortal = false
        let userArea: string = props.identity.profile.area
        if (userArea == 'Customers') {
          redirectToClientPortal = true
        }

        let apps = result.data.applications.items.filter(o => hasAccessWrapper(o))

        let clientPortalApp = apps.firstOrDefault(x => x.id == result.data.clientPortalAppId)
        let identityApp = result.data.applications.items.firstOrDefault(x => x.id == "1")
        if (clientPortalApp && redirectToClientPortal && !hasAccessWrapper(identityApp)) {
          setTimeout(() => {
            window.location.replace(clientPortalApp.privateUrl)
          }, 1000)

          return
        }

        var notPrerealse = apps.filter(x => !x.prerelease)
        if (notPrerealse.length == 1) {
          var app = notPrerealse[0]
          if (app.id != "1") {
            setTimeout(() => {
              window.location.replace(app.privateUrl)
            }, 1000)
            return
          }
        }
        setApps(apps)
        setAdditionalApps(result.data.applications.items.filter(o => !o.hasAccess && o.publicUrl && o.publicUrl != "")),
          setBusy(false)
        setTimeout(() => {
          setwhileRedirect(false)
        }, 1000)


      }).catch(error => {
        setBusy(false)
        setError(error)
      })
    }, [inputRef]
  )






  const { t } = props

  const showPrerelease = getParameterByName(window.location.href, "prerelease")
  if (whileRedirect) {
    return <>
      <div
        style={{
          position: 'fixed',
          height: '100%',
          top: 0,
          left: 0,
          width: '100%',
          background: "rgba(0,0,0,0.7)",
          textAlign: 'center',
          zIndex: 999
        }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '100px',
            marginTop: '-50px',
            marginLeft: '-200px',
            width: '400px',
            textAlign: 'center',
          }}
        >

          <div style={{ marginLeft: '55px', marginBottom: '10px', display: 'flex' }}>
            <Spin style={{ marginRight: '10px', marginTop: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
            <img style={{ marginTop: 12, background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px' }} alt="fi-logo" src={logo} height={34} />
            <span style={{ fontSize: 18, float: 'right', marginTop: 12, marginLeft: 5, fontWeight: 'bold', color: 'white' }}>
              Helping ideas grow
            </span>
          </div>

          <strong style={{ marginLeft: '10px', color: 'white' }}>{t('Wait while redirect...')}</strong>
        </div>
      </div>
    </>
  }
  if (apps && apps.length === 0) {
    return <p>{t("It seems that you dont have access to any application. Please contact your administartor")}</p>
  }


  const patterClass = (app: AppInfo) => {
    let className = ""
    if (onlyFavorites && !app.isUserFavorite) className = "hidden"
    if (!searchPattern || searchPattern.length == 0) return app.isUserFavorite ? "user-favorite" : className
    if (app.name) {

      if (app.name.toLowerCase().includes(searchPattern.toLowerCase())) return app.isUserFavorite ? "user-favorite" : className
    }
    if (app.description) {

      if (app.description.toLowerCase().includes(searchPattern.toLowerCase())) return app.isUserFavorite ? "user-favorite" : className
    }
    if (app.title) {
      if (app.title.toLowerCase().includes(searchPattern.toLowerCase())) return app.isUserFavorite ? "user-favorite" : className
    }
    return "hidden"
  }
  const setFavorites = (isFavorite: boolean, appid: string) => {
    setBusy(true)
    httpService.post<any, any>(`/api/v1/admin/applications/favorites`, { IsFavorite: isFavorite, AppId: appid }).then(result => {
      let currentsApps = apps
      var current = apps.first(x => x.id == appid)
      current.isUserFavorite = isFavorite
      setBusy(false)

    }).catch(error => {
      setBusy(false)
      setError(error)
    })
  }
  const getFavorites = (isFavorite: boolean, appid: string) => {

    if (isFavorite)
      return <Tooltip title={t("Remove this app from your favorites")}><HeartFilled onClick={(event) => { event.preventDefault(); setFavorites(false, appid) }} color="red" /></Tooltip>
    return <Tooltip title={t("Add this app to your favorites")}><HeartOutlined onClick={(event) => { event.preventDefault(); setFavorites(true, appid) }} color="red" /></Tooltip>
  }
  const getFavoriteFilter = () => {
    if (onlyFavorites)
      return <Tooltip title={t("Show all Applications")}> <Button style={{ marginTop: -5, marginLeft: 10, color: "red", width: 35 }} onClick={() => setOnlyFavorites(false)} icon={<HeartFilled color="red" />}>

      </Button></Tooltip>

    return <Tooltip title={t("Show only favorites")}> <Button style={{ marginTop: -5, marginLeft: 10, color: "red", width: 35 }} onClick={() => setOnlyFavorites(true)} icon={<HeartOutlined color="red" />}>
    </Button></Tooltip>

  }
  return <>
    <Card >
      <Row >
        <Col span={24}>

          <Row gutter={10}>
            <Col span={24} >
              <div style={{ display: "flex" }} className={"marginAuto maxW1200"}>
                <h2 style={{ fontSize: 14, fontWeight: 'bold', marginRight: 30, whiteSpace: "nowrap" }}>{t("Find, organize and enter easily in your FI Groups Apps")}</h2>
                <Search style={{ marginTop: -5, margin:"-5px 10px 0px 0px" }} placeholder={t("Search application...")} onSearch={(text) => setSearchPatter(text)} />
                <Tooltip title={t("Sort applications")}>
                  <Button style={{ marginTop: -5, color: "rgb(0,0,164)", width:60 }} onClick={() => setOrderActive(true)} icon={<MenuOutlined />}>
                  </Button></Tooltip>
                {getFavoriteFilter()}
              </div>
            </Col>

          </Row>

          <div style={{ marginTop: 30 }} className="tiles wide maxW1200 marginAuto">
            {error && (
              <Alert
                type='error'
                message={t('An error occurred')}
                description={error}
              />
            )}
            {apps.map((app, index) => (
              <Fragment key={app.id}>
                {!app.prerelease && (
                  <div className={"app-card " + patterClass(app)}  >
                    <a href={app.privateUrl || "#"}><Button className={"menu-item menu-item-release menu-item-access"}>
                      <div>
                        <div className="appfavorite reaction-media favorites">{getFavorites(app.isUserFavorite, app.id)}</div>
                        <AppIcon id={app.id} cache={props} />
                        <b style={{ color: "rgb(0,0,164)" }}>{app.title}</b>
                        <div style={{ marginTop: 60 }}>  {t(app.description)}</div>
                      </div>
                    </Button></a>
                  </div>
                )}
                {app.prerelease && showPrerelease && (
                  <div className={"app-card " + patterClass(app)} >
                    <a href={app.privateUrl || "#"}> <Button className={"menu-item menu-item-prerelease menu-item-access"} >
                      <div>
                        <div className="appfavorite reaction-media favorites">{getFavorites(app.isUserFavorite, app.id)}</div>
                        <AppIcon id={app.id} cache={props} />
                        <b style={{ color: "rgb(0,0,164)" }}>{app.title}</b>

                        <div style={{ marginTop: 60 }}>  {t(app.description)}</div>

                      </div>
                    </Button></a>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
          <h4 className="home-header" style={{ marginBottom: "24px" }}>{t("Do you want to access these applications? Ask us!")}</h4>
          <div className={'tiles wide maxW1200 marginAuto'}>
            {additionalApps.map(app => (
              <Fragment key={app.id}>
                {!app.prerelease && (
                  <div className={"app-card "} >
                    <a href={app.publicUrl || "#"}> <Button className={"menu-item menu-item-prerelease menu-item-access"} >
                      <div>

                        <AppIcon id={app.id} cache={props} />
                        <b style={{ color: "rgb(0,0,164)" }}>{app.title}</b>

                        <div style={{ marginTop: 60 }}>  {t(app.description)}</div>

                      </div>
                    </Button></a>
                  </div>
                )}
                {app.prerelease && showPrerelease && (
                  <div className={"app-card "} >
                    <a href={app.publicUrl || "#"}> <Button className={"menu-item menu-item-prerelease menu-item-access"} >
                      <div>
                        <AppIcon id={app.id} cache={props} />
                        <b style={{ color: "rgb(0,0,164)" }}>{app.title}</b>

                        <div style={{ marginTop: 60 }}>  {t(app.description)}</div>

                      </div>
                    </Button></a>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
          {orderActive && <SortAppList showPrerelease={showPrerelease} axis="xy" apps={apps} onClose={() => setOrderActive(false)} onOrderChange={(list) => setApps(list)} />}
        </Col>

      </Row>
    </Card>

  </>
}
export default withTranslation()(withRouter(withCache(withIdentity(AdminMenu))) as any)
